import React, { useState } from "react"

import { Redirect } from "react-router"
import { connect } from "react-redux"
import { StartConfirmation } from "../../../store/actions/actionsData.js"

import SendIcon from "@mui/icons-material/Send"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import FriendEntries from "./friendEntries"

import Summary from "../../displays/navigation/index.js"
import Section from "../../displays/layout/section"

import { useTheme } from "@material-ui/core/styles"

const Friends = props => {
    const theme = useTheme()
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const [menuFriendId, setMenuFriendId] = useState(null)

    const openMenu = friend_id => e => {
        setMenuAnchorEl(e.currentTarget)
        setMenuFriendId(friend_id)
    }
    const closeMenu = () => {
        setMenuAnchorEl(null)
        // setMenuFriendId(null)
    }

    const handleDeleteUser = friend_id => {
        closeMenu()
        const friendName = props.friends.find(f => f.id === friend_id)?.name
        const action_pending = "DeleteUser"
        const action_title = "Placeholder user deletion"
        const action_body = `Are you sure you want to delete ${friendName} (placeholder user)? You will still see ${friendName} in groups you are both members of.`
        const action_cta = `delete ${friendName} (placeholder user)`
        props.StartConfirmation(action_pending, friend_id, action_title, action_body, action_cta)
    }

    const handleSendLogin = friend_id => {
        closeMenu()
        const friendName = props.friends.find(f => f.id === friend_id)?.name
        const action_pending = "SendLogin"
        const action_title = "Send login link"
        const action_body = `Are you sure you want to send a login link to ${friendName}? (you cannot do this very often)`
        const action_cta = `Send login to ${friendName}`
        props.StartConfirmation(action_pending, friend_id, action_title, action_body, action_cta)
    }

    const menuComponent = () => {
        const friend_status = props.friends.find(f => f.id === menuFriendId)?.status
        return (
            <Menu id="simple-menu" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {friend_status !== "placeholder" ? (
                    <MenuItem onClick={() => handleSendLogin(menuFriendId)} style={{ display: "flex", justifyContent: "space-between" }}>
                        Send login
                        <SendIcon style={{ marginLeft: "8px" }} />
                    </MenuItem>
                ) : (
                    <MenuItem onClick={() => handleDeleteUser(menuFriendId)} style={{ color: theme.palette.negative.main }}>
                        Delete placeholder
                    </MenuItem>
                )}
            </Menu>
        )
    }

    if (!props.loggedIn) {
        return <Redirect to="/home" />
    }

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", minHeight: "100vh", paddingBottom: "200px" }}>
            <Summary />
            <Section style={{ marginTop: "200px", padding: "0px" }}>
                <FriendEntries openMenu={openMenu} />
                {menuComponent()}
            </Section>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loggedIn: state.display.loggedIn,
        friends: state.data.friends,
        user: state.data.user,
        groups: state.data.groups
    }
}

const mapDispatchToProps = dispatch => {
    return {
        StartConfirmation: (action_pending, action_id, action_title, action_body, action_cta) => dispatch(StartConfirmation(action_pending, action_id, action_title, action_body, action_cta))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Friends)
