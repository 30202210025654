import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import { useSpring, animated } from "react-spring"
import { useTheme } from "@material-ui/core/styles"

import { connect } from "react-redux"
import SettleUp from "./settleUp"
import Payment from "./payment"

import ModalHeader from "../../../displays/modalHeader.js"
import { closeModals } from "../../../../store/actions/actionsDisplay.js"

export const SettleUpInput = props => {
    const theme = useTheme()

    const [settle, setSettle] = useState(true)
    const group = props.groups.find(g => g.id === props.addPaymentData.group_id)
    const transactions = props.summaries.groups.find(g => g.id === group.id)?.transactions || []
    const groupCurrencyObject = props.currencies.find(c => c.id === group.default_currency)

    const handleClose = () => {
        props.closeModals()
    }

    const springProps = useSpring({
        transform: `translateX(${settle ? 0 : -50}%)`,
        config: { tension: 400, friction: 40 }
    })
    const springProps2 = useSpring({
        transform: `translateX(${settle ? -50 : 50}%)`,
        config: { tension: 400, friction: 40 }
    })

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
                <ModalHeader title={"Add Transfer"} onClose={() => handleClose()} />
                <div style={{ display: "inline-flex", justifyContent: "space-around", width: "100%", position: "relative" }}>
                    <h2 onClick={() => setSettle(true)} className="group_change_title">
                        Settle Up
                    </h2>
                    <h2 onClick={() => setSettle(false)} className="group_change_title">
                        Payment
                    </h2>
                    <animated.div style={{ ...springProps2, background: theme.palette.subSection.main, height: "40px", width: "50%", position: "absolute", bottom: "0px", borderRadius: "20px 20px 0 0" }}></animated.div>
                </div>
                <animated.div style={{ marginTop: "-16px", width: "200%", display: "flex", willChange: "transform", ...springProps }}>
                    <SettleUp group_id={group.id} transactions={transactions} groupCurrencyObject={groupCurrencyObject} />
                    <Payment group_id={group.id} transactions={transactions} groupCurrencyObject={groupCurrencyObject} />
                </animated.div>
            </div>
            <div style={{ boxSizing: "border-box", display: "flex", flexDirection: "column", width: "100%", gap: "16px", padding: "16px" }}>
                <Button variant="outlined" color="primary" onClick={() => handleClose()}>
                    Cancel
                </Button>
                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {submitCopy()}
                </Button> */}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.user,
        friends: state.data.friends,
        currencies: state.data.currencies,
        groups: state.data.groups,
        addPaymentData: state.data.addPaymentData,
        summaries: state.data.summaries
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeModals: () => dispatch(closeModals())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettleUpInput)
