const CONFIG_COMPANY_NAME = "Tallyez"

let CONFIG_DOMAIN = "tallyez.com"
let CONFIG_DOMAIN_URL = `https://${CONFIG_DOMAIN}`
let CONFIG_APP_DOMAIN = `https://${CONFIG_DOMAIN}:3000/`
let CONFIG_API_DOMAIN = `https://api.${CONFIG_DOMAIN}/`
let CONFIG_WEBSOCKET_DOMAIN = `https://websocket.${CONFIG_DOMAIN}/`

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CONFIG_DOMAIN = "127.0.0.1"
    CONFIG_DOMAIN_URL = `http://${CONFIG_DOMAIN}:3000`
    CONFIG_APP_DOMAIN = `http://${CONFIG_DOMAIN}:3000/`
    CONFIG_API_DOMAIN = `http://${CONFIG_DOMAIN}:5000/`
    CONFIG_WEBSOCKET_DOMAIN = `http://${CONFIG_DOMAIN}:4001/`
} else {
}

const CONFIG_PUSH_VAPID_PUBLIC = "BNKJRtN_nUU0OAt8jqI6I6BFSOTU37_TTmbMftTz8qJpEaxev0nPn7FQj6F793FSJbZ3OnC8QrQagNA64rTXxuY"

module.exports = {
    CONFIG_DOMAIN,
    CONFIG_DOMAIN_URL,
    CONFIG_API_DOMAIN,
    CONFIG_APP_DOMAIN,
    CONFIG_WEBSOCKET_DOMAIN,
    CONFIG_COMPANY_NAME,
    CONFIG_PUSH_VAPID_PUBLIC
}
