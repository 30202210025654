import React from "react"

import Section from "../../displays/layout/section"
import { useTheme } from "@material-ui/core/styles"

import { closeModals } from "../../../store/actions/actionsDisplay"
import { useTransition, useSpring, animated } from "react-spring"

import AddOrEditGroup from "./group/addOrEditGroup"
import AddExpenseModal from "./addExpense"
import JoinGroup from "./joinGroup"
import GroupStats from "../../pages/groups/groupStats"
import SettleUpOrPayment from "./settleUpOrPayment"

import { connect } from "react-redux"

const AddExpense = props => {
    const theme = useTheme()

    const show = props.display.addGroup || props.display.addExpense || props.shareJWT || props.display.viewGroupStats || props.display.addSettleUp

    const transitions = useTransition(show, null, {
        config: { mass: 1, tension: 200, friction: 10, clamp: true },
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const spring = useSpring({
        config: { mass: 1, tension: 500, friction: 30, clamp: false },
        transform: show ? "translateY(0%)" : "translateY(50%)",
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    })

    let modalToDisplay = <></>
    if (props.display.addGroup) {
        modalToDisplay = <AddOrEditGroup />
    } else if (props.display.addExpense) {
        modalToDisplay = <AddExpenseModal />
    } else if (props.shareJWT) {
        modalToDisplay = <JoinGroup />
    } else if (props.display.viewGroupStats) {
        modalToDisplay = <GroupStats />
    } else if (props.display.addSettleUp) {
        modalToDisplay = <SettleUpOrPayment />
    }

    return transitions.map(
        ({ item, key, props }) =>
            item && (
                <animated.div
                    key={key}
                    style={{ ...props, color: theme.palette.text.main }}
                    // onClose={() => handleClose()}
                    className="modal"
                >
                    <animated.div style={{ ...spring }}>
                        <Section style={{ margin: 0, height: "90vh" }}>
                            <div style={{ overflow: "hidden auto", height: "100%" }}>{modalToDisplay}</div>
                        </Section>
                    </animated.div>
                </animated.div>
            )
    )
}

const mapStateToProps = state => {
    return {
        display: state.display,
        user: state.data.user,
        shareJWT: state.auth.shareJWT
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeModals: () => dispatch(closeModals())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExpense)
